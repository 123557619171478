import { Button, Checkbox, Modal } from 'antd';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import React, { useMemo, useState } from 'react';
import apiurl from '../../../service/apiurl';
import { saveOrUpdateInPager } from '../../../utils/utils';
import { ICompProps } from './common';

const RespForm: React.FC<ICompProps> = ({
  crudCtx,
  pagerCtx
}) => {

  const [roleIds, setRoleIds] = useState<CheckboxValueType[]>(() => {
    const d = crudCtx.record.respDuty;
    const ret = [];
    for (let i = 0; i < 7; i++) {
      if (d & (1 << i)) {
        ret.push(1 << i);
      }
    }
    return ret;
  });

  const [loading, setLoading] = useState<boolean>(false);

  const submit = async () => {
    if (roleIds.length === 0) {
      return;
    }
    const userId: number = crudCtx.record.id;
    const params = {
      id: userId,
      responsible: roleIds.length > 0 ? '1' : '0',
      respDuty: roleIds.reduce((t: any, c: any) => t | c, 0)
    };

    saveOrUpdateInPager(apiurl.upgramurl.user.updateResp, params, pagerCtx, crudCtx, setLoading);
  }

  return (
    <Modal
      visible
      title='责任人角色'
      onCancel={() => crudCtx.goto(null, null)}
      footer={[
        <Button key="submit" type="primary" disabled={roleIds.length === 0} onClick={submit} loading={loading}>提交</Button>,
        <Button key="back" onClick={() => crudCtx.goto(null, null)}>取消</Button>
      ]}
    >
      <div style={{ border: '1px solid #d9d9d9', padding: '8px 16px', height: 300, overflowY: 'auto' }}>
        <Checkbox.Group
          style={{ width: '100%', height: '100%' }}
          value={roleIds}
          onChange={setRoleIds}
        >

          <Checkbox value={1 << 0}>党政一把手</Checkbox>
          <Checkbox value={1 << 1}>分管责任人</Checkbox>
          <Checkbox value={1 << 2}>值班人员</Checkbox>
          <Checkbox value={1 << 3}>包保责任人</Checkbox>
          <Checkbox value={1 << 4}>水管站负责人</Checkbox>
          <Checkbox value={1 << 5}>信息检测员</Checkbox>
          <Checkbox value={1 << 6}>其他</Checkbox>
        </Checkbox.Group>
      </div>
    </Modal>
  )
}

export default RespForm