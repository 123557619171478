import { ExportOutlined, PlusOutlined, SearchOutlined, UserAddOutlined } from '@ant-design/icons'
import ProForm, { ProFormCheckbox, ProFormSelect, ProFormText } from '@ant-design/pro-form'
import { Button, Card, Checkbox, Radio, Select, Space } from 'antd'
import React, { useRef, useState } from 'react'
import apiurl from '../../../service/apiurl'
import { pType } from '../../../service/appconsts'
import { ICompProps, TableOption } from './_'

const SearchBar: React.FC<{
  ctx: ICompProps
}> = ({ ctx }) => {
  const { crud, pager, searchForm, isAdmin } = ctx;


  const [buzy, setBuzy] = useState(false);


  const test = () => {
    if (buzy) {
      return;
    }

    setTimeout(() => {
      setBuzy(false);
    }, 3000);

    setBuzy(true);
    const params = { ...searchForm.getFieldsValue() };
    if (params.respDuty) {
      params.respDuty = params.respDuty.reduce((t: any, c: any) => t | c, 0) as any;
    }

    ctx.exportData(params);
  }

  return (
    <Card>
      <ProForm
        layout='inline'
        form={searchForm}
        onFinish={
          async (data) => {
            const respDuty = data.respDuty || [];
            ctx.searchParams({
              user: data.user,
              phone: data.phone,
              showall: data.showall ? '1' : '0',
              respDuty: respDuty.reduce((t: any, c: any) => t | c, 0)
            })
          }
        }
        submitter={false}
      >
        <ProFormText label="姓名" name="user" width={120} />
        <ProFormText label="电话号码" name="phone" width={120} />

        <ProFormSelect
          label="责任人类型"
          // placeholder="责任人类型过滤，可多选"
          name="respDuty"
          mode='multiple'
          width={300}
          allowClear
          options={[
            { value: pType['党政一把手'], label: '党政一把手' },
            { value: pType['分管责任人'], label: '分管责任人' },
            { value: pType['值班人员'], label: '值班人员' },
            { value: pType['包保责任人'], label: '包保责任人' },
            { value: pType['水管站负责人'], label: '水管站负责人' },
            { value: pType['信息检测员'], label: '信息检测员' },
            { value: pType['其他'], label: '其他' },
          ]}
        >
        </ProFormSelect>
        <ProFormCheckbox label="全部" name="showall" />
        <Space>
          <Button htmlType='submit' icon={<SearchOutlined />} type="primary">查询</Button>
          <Button disabled={buzy} icon={<ExportOutlined />} onClick={test}>导出</Button>
          {
            isAdmin ? (<Button icon={<UserAddOutlined />} onClick={() => crud.goto('add', {})}>新增</Button>) : null
          }
        </Space>

        <div style={{ flexGrow: 1 }}></div>


      </ProForm>
    </Card>
  )
}

export default SearchBar