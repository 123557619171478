import ProForm, {
  ProFormInstance, ProFormText
} from '@ant-design/pro-form';
import React, { useMemo, useRef } from 'react';
import CenterForm from '../../../components/crud/CenterForm';
import { DEF_INPUT_LEN } from '../../../components/crud/FormLayoutProps';
import apiurl from '../../../service/apiurl';
import { saveOrUpdateInPager } from '../../../utils/utils';
import { phoneValidator } from "../../../utils/validator";
import { Checkbox, Form } from 'antd';
import { PFormShape, ICompProps } from './_';
import AdcdTreeSelect from './AdcdTreeSelect';
import { pTypeStr } from '../../../service/appconsts';


const PForm: React.FC<{
  ctx: ICompProps
}> = ({ ctx }) => {
  const { crud, pager } = ctx;

  const formRef = useRef<ProFormInstance<PFormShape>>();

  const submit = async (values: PFormShape) => {
    const { _respDuty, _adinfo, ...params } = values;

    if (_respDuty.length > 0) {
      params.respDuty = _respDuty[0];

      for (let i = 1; i < _respDuty.length; i += 1) {
        params.respDuty = params.respDuty | _respDuty[i];
      }
    } else {
      params.respDuty = 0;
    }
    params.adcd = _adinfo.adcd;

    console.log(params);

    saveOrUpdateInPager(apiurl.upgramurl.user.editKai, params, pager, crud);
  }

  const reset = () => formRef?.current?.resetFields();

  const record = useMemo(() => {
    const r = crud.record;
    const rd = r.respDuty;
    const _respDuty: number[] = [];
    for (const key of Object.keys(pTypeStr)) {
      if (rd & parseInt(key)) {
        _respDuty.push(parseInt(key));
      }
    }
    r._respDuty = _respDuty;
    r._adinfo = {
      adcd: r.adcd,
      adnm: r.adnm,
    }

    return r;
  }, []);

  if (crud.mode !== 'editP') {
    return null;
  }

  return (
    <CenterForm>
      <ProForm
        formRef={formRef}
        initialValues={record}
        onFinish={submit}
        onReset={reset}
      >
        <ProFormText name="id" hidden />

        <ProFormText
          name="user"
          label="用户姓名"
          width={DEF_INPUT_LEN}
          placeholder="请输入用户姓名"
          rules={[{ required: true, message: '用户姓名不能为空' }]}
        />
        <ProFormText
          name="phone"
          label="联系电话"
          width={DEF_INPUT_LEN}
          rules={[{ required: true, validator: phoneValidator }]}
        />
        <ProFormText
          name="position"
          label="职务"
          width={DEF_INPUT_LEN}
        />
        <ProFormText
          name="duty"
          label="职责"
          width={DEF_INPUT_LEN}
        />
        <ProFormText
          name="org"
          label="在职单位"
          width={DEF_INPUT_LEN}
        />

        <Form.Item
          name="_adinfo"
          label="归属行政区划"
          rules={[{ required: true }]}
        >
          <AdcdTreeSelect />
        </Form.Item>

        <Form.Item
          name="_respDuty"
          label="责任人类型"
        >
          <Checkbox.Group className='vertical-checkgroup'>
            <Checkbox value={1 << 0}>党政一把手</Checkbox>
            <Checkbox value={1 << 1}>分管责任人</Checkbox>
            <Checkbox value={1 << 2}>值班人员</Checkbox>
            <Checkbox value={1 << 3}>包保责任人</Checkbox>
            <Checkbox value={1 << 4}>水管站负责人</Checkbox>
            <Checkbox value={1 << 5}>信息检测员</Checkbox>
            <Checkbox value={1 << 6}>其他</Checkbox>
          </Checkbox.Group>
        </Form.Item>
      </ProForm>
    </CenterForm>
  );
};

export default PForm;